/* eslint-disable react/no-danger */
import React from 'react'
import { string, number } from 'prop-types'
import { motion } from 'framer-motion'
// import { useInView } from 'react-intersection-observer'
import SectionHeading from '@/components/global/SectionHeading'
import RichText from '@/utils/RichText'
import AnimateEntrance from '@/utils/AnimateEntrance'
import { containerVariants, textVariants } from '@/utils/transitions'
import ParallaxContent from '@/components/Tracking/ParallaxContent'

function PurpleRow({ id, title, subHeading, body, index, image, video }) {
	return (
		<AnimateEntrance
			id={id}
			threshold={0.1}
			variants={containerVariants}
			rootMargin="-100px 0px"
			className="relative lg:site-grid grid-dense lg:row-1"
		>
			<div className="absolute inset-x-0 top-0 w-full bg-secondary bottom-32 lg:relative lg:h-full lg:column-5/10 2xl:column-7/8 lg:row-1" />
			<motion.div
				variants={textVariants}
				className="relative z-10 flex flex-col justify-center px-8 pt-20 lg:max-w-md lg:py-16 lg:px-0 lg:row-1 lg:column-10/3 xl:column-10/4 xl:pr-12"
			>
				<SectionHeading className="mb-12 text-xs text-white lg:hidden">
					{index}. {subHeading}
				</SectionHeading>

				<motion.h2
					variants={textVariants}
					className="mb-8 leading-tight text-white text-h2-fluid font-museoSansBold"
				>
					{title}
				</motion.h2>

				<motion.div variants={textVariants}>
					<RichText className="mb-16 text-white lg:mb-0" content={body} />
				</motion.div>
			</motion.div>
			<ParallaxContent image={image} video={video} blockType="purpleRow" />
		</AnimateEntrance>
	)
}

PurpleRow.propTypes = {
	id: string.isRequired,
	title: string.isRequired,
	subHeading: string.isRequired,
	body: string.isRequired,
	index: number.isRequired,
	image: string
}

export default PurpleRow
