import React from 'react'
import Form from '@/components/Form'

function ContactForm() {
	return (
		<div className="overflow-hidden bg-lightgrey">
			<div className="px-8 wrapper">
				<div className="max-w-6xl mx-auto">
					<div className="py-20 mx-auto lg:flex lg:py-32 lg:-ml-32">
						<div className="w-full mb-8 text-left md:pr-10 lg:flex lg:flex-col lg:pl-32 lg:w-1/2">
							<h2 className="max-w-lg pb-6 leading-tight text-darkgrey text-h2-fluid font-museoSans">
								Be the first to know
							</h2>
							<p className="mb-8 md:w-3/3 ">
								Get the latest curated news on haptics, tracking, and
								next-generation interfaces – straight to your inbox.
							</p>
							<p className="mb-8 md:w-3/3 ">
								By signing up, you are agreeing to our{' '}
								<a
									className="underline pardotTrackClick text-primary"
									href="/privacy-policy/"
									rel="nofollow"
									target="_blank"
								>
									privacy policy
								</a>
								.
							</p>
						</div>
						<div className="w-full lg:flex lg:flex-col mb lg:pl-32 lg:w-1/2">
							<Form
								form_name="form"
								form_id="https://www2.ultraleap.com/l/727963/2019-09-18/25v3l"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ContactForm
