import { useEffect, useCallback, useState } from 'react'

const useScrollBehavior = () => {
	const [supported, setSupported] = useState(true)

	useEffect(() => {
		setSupported('scrollBehavior' in document.documentElement.style)
	}, [])

	return supported
}

function useScrollTo() {
	const supported = useScrollBehavior()

	return useCallback(
		(x, y) => {
			if (supported) {
				window.scrollTo({ top: y, left: x, behavior: 'smooth' })
			} else {
				const startX = window.pageXOffset
				const startY = window.pageYOffset
				const duration = 1000
				let startTime = null

				const callback = dataTime => {
					if (!startTime) {
						startTime = dataTime
					}

					const progress = (dataTime - startTime) / duration
					const nextX = startX + progress * (x - startX)
					const nextY = startY + progress * (y - startY)
					window.scrollTo(nextX, nextY)

					if (progress < 1) {
						requestAnimationFrame(callback)
					}
				}

				requestAnimationFrame(callback)
			}
		},
		[supported]
	)
}

export default useScrollTo
