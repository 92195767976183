import React from 'react'
import { Parallax } from 'react-parallax'
import classNames from 'classnames'
import useMediaQuery from '@/hooks/useMediaQuery'
const ComponentHeight = 500

const ParallaxContent = ({ image, video, blockType }) => {
	const desktop = useMediaQuery('(min-width: 64em)')
	const mediaWidth = `w-${blockType === 'whiteRow' ? 12 : 11}/12`
	return (
		<Parallax
			bgImage={image || video}
			strength={50}
			style={{ overflow: 'visible' }}
			className={classNames(
				{
					'relative z-10 lg:top-4 lg:column-3/6 2xl:column-5/4 lg:row-1 lg:pl-0 lg:py-8':
						blockType === 'purpleRow'
				},
				{
					'mb-8 lg:column-8/7 2xl:column-10/5 lg:mb-0 lg:relative lg:top-4':
						blockType === 'whiteRow'
				}
			)}
			bgImageStyle={{ height: 0 }}
			renderLayer={percentage => (
				<div>
					<div
						style={{ minHeight: desktop ? ComponentHeight : 'auto' }}
						className={`relative ${mediaWidth} mx-auto`}
					>
						<div
							style={{
								bottom: desktop ? `${percentage * 50}%` : 0,
								position: desktop ? 'absolute' : 'relative',
								left: '0%',
								height: 'auto'
							}}
							className={classNames(
								{ flex: !image },
								'justify-center text-4xl text-white font-cera-bold'
							)}
						>
							{image && <img src={image} alt="para" height="auto" />}
							{video && (
								<video
									loop
									muted
									playsInline
									autoPlay
									preload="auto"
									src={video}
									className="object-cover w-full h-full"
								/>
							)}
						</div>
					</div>
				</div>
			)}
		/>
	)
}

export default ParallaxContent
