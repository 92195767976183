/* eslint-disable react/no-danger */
import React from 'react'
import { string, number } from 'prop-types'
import { motion } from 'framer-motion'
import SectionHeading from '@/components/global/SectionHeading'
import AnimateEntrance from '@/utils/AnimateEntrance'
import ParallaxContent from '@/components/Tracking/ParallaxContent'
import { containerVariants, textVariants } from '@/utils/transitions'

function WhiteRow({ id, title, subHeading, body, index, image, video }) {
	return (
		<AnimateEntrance
			variants={containerVariants}
			id={id}
			threshold={0.1}
			rootMargin="-100px 0px"
			className="flex flex-col px-8 lg:pl-0 lg:site-grid grid-dense"
		>
			<SectionHeading className="pr-6 mb-12 text-xs text-darkgrey lg:hidden">
				{index}. {subHeading}
			</SectionHeading>
			<div className="contents lg:block lg:column-3/4 2xl:column-5/4">
				<motion.h2
					variants={textVariants}
					className="pr-6 mb-8 leading-tight text-h2-fluid font-museoSansBold text-secondary lg:pr-0"
				>
					{title}
				</motion.h2>
				<motion.div
					variants={textVariants}
					className="order-last"
					dangerouslySetInnerHTML={{ __html: body }}
				/>
			</div>
			<ParallaxContent image={image} video={video} blockType="whiteRow" />
		</AnimateEntrance>
	)
}

WhiteRow.propTypes = {
	id: string.isRequired,
	title: string.isRequired,
	subHeading: string.isRequired,
	body: string.isRequired,
	index: number.isRequired,
	image: string
}

export default WhiteRow
